<template lang="pug">
	main#contato
		Banner
		Header
		Formulario(formTitle="Está na hora do seu negócio ir além!")
		More
</template>

<script>
import Banner from '@sections/Contato/Banner/Banner'
import More from '@sections/Contato/More/More'
import Header from '@components/Header/Header'
import Formulario from '@components/Formulario/Formulario'

export default {
	name: "view-contato",
	components: {
		Banner,
		Header,
		Formulario,
		More
	},
	metaInfo() {
		return {
			title: 'contato',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Contato.styl"></style>
