<template lang="pug">
    section#more
        .wrapper
            .line
                .item
                    h4 Vagas
                    p
                        |Fique ligado no nosso LinkedIn.
                        br
                        |Se preferir, envie currículo para:
                    span
                        a(href="mailto:vagas@mindservice.co") vagas@mindservice.co 
                        br
                        |ou 
                        a(href="#") preencha o formulário
                .item
                    h4 Conecte
                    p
                        |Para outros tipos de conexões, 
                        br
                        |envie um "olá" para nós:
                    span
                        a(href="mailto:conecte@mindservice.co") conecte@mindservice.co
            .line
                .item
                    h4 Curitiba
                    p
                        |R. Ver. Washington Mansur, 170
                        br
                        |Ahú, Curitiba - PR, 80540-210
                        br
                        |Das 8:30 às 18:30
</template>

<script>
export default {
    name: "section-more",
}
</script>

<style lang="stylus" scoped src="./More.styl"></style>