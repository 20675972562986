import { render, staticRenderFns } from "./Contato.vue?vue&type=template&id=14bb3a75&scoped=true&lang=pug&"
import script from "./Contato.vue?vue&type=script&lang=js&"
export * from "./Contato.vue?vue&type=script&lang=js&"
import style0 from "./Contato.styl?vue&type=style&index=0&id=14bb3a75&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14bb3a75",
  null
  
)

export default component.exports