<template lang="pug">
    section#banner
        SvgIcon(data="@svgs/equipe/explosion.svg", original).explosion
        .wrapper
            .svgs
                SvgIcon(data="@svgs/icon_01.svg", original)._svg01
                SvgIcon(data="@svgs/icon_02.svg", original)._svg02
                SvgIcon(data="@svgs/icon_03.svg", original)._svg03
            .container
                p Vamos marcar um café e
                h2
                    |R
                    span._1100 e
                    |v
                    span._1100 o
                    |l
                    span._1100 u
                    |c
                    span._1100 io
                    |n
                    span._1100 a
                    |r
                    br
                    |m
                    span._1900 e
                    |nt
                    span._1900 e
                    |s?
            SvgIcon(data="@svgs/m.svg", original).m
</template>

<script>
export default {
    name: "section-banner",
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
